import { PageProps } from 'gatsby';
import { ActionData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import action from '~/params/action.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageAutomatisationActionUpdate: FC<EspaceProps & PageProps & UserProps> =
  function ({ espace, params: { espaceId, actionId }, user }) {
    return (
      <TemplateEspace espace={espace} user={user}>
        <CMSView>
          <Form
            docId={actionId}
            model={
              new ActionData({
                espaceId,
                params: action,
              })
            }
            type="update"
          />
        </CMSView>
      </TemplateEspace>
    );
  };
export default requireEspace(PageAutomatisationActionUpdate);
